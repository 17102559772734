@import '@fortum/elemental-ui/FortumSans.css';

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  padding: 0;
  margin: 0;
  overflow-wrap: anywhere;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove default list style */
ol,
ul {
  list-style: none;
}

/* Show replaced elements as block */
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
}

/* Constrain images and videos to parent width */
img,
video {
  max-width: 100%;
  height: auto;
}

/* Reset button styles */
button {
  background-color: transparent;
  border-width: 0;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
}
